<script setup lang="ts">
const route = useRoute();

const props = defineProps({
    blok: {
        type: Object,
        default: () => ({}),
    },
});

const href = computed(() => {
    const link = props.blok.link;
    const market: Market = route.query?.market;

    if (market) {
        return `${link.cached_url}?market=${market}`;
    } else {
        return link.cached_url;
    }
});
</script>

<template>
    <li>
        <a
            :href="href"
            class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-white lg:border-0 lg:hover:text-primary-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
            >{{ blok.text }}</a
        >
    </li>
</template>
